import { FC, SetStateAction, useState } from 'react'
import {
  HiOutlineBeaker,
  HiOutlineChartSquareBar,
  HiOutlineCheck,
  HiOutlineChevronRight,
  HiOutlineCloudUpload,
  HiOutlineCode,
  HiOutlineCog,
  HiOutlineCollection,
  HiOutlineLogout,
  HiOutlineMenuAlt1,
  HiOutlineServer,
  HiStatusOnline,
} from 'react-icons/hi'
import { useStytchB2BClient } from '@stytch/nextjs/b2b'

import {
  EnvironmentLabel,
  EnvironmentPicker,
  EnvironmentPickerOption,
  EnvironmentPickerOptionLabel,
  EnvironmentPickerOptions,
  EnvironmentWrapper,
  LinksList,
  ListItem,
  ListLink,
  LogoWrapper,
  LogoutWrapper,
  SidebarFooter,
  SidebarWrapper,
  UserEmail,
} from './style'
import Logo from '@components/core/logo'
import anchoTheme, { LogoColor } from '@lib/theme'
import { useRouter } from 'next/router'
import { useAncho } from '@context/ancho'
import { IAnchoContext } from '@types'
import { AnchoEnvironment } from '@lib/ancho'

interface SidebarProps {
  updateAncho?: (newAncho: Partial<IAnchoContext>) => void
  visible?: boolean
}

const Sidebar: FC<SidebarProps> = ({
  updateAncho = () => {},
  visible = false,
}) => {
  const ancho = useAncho()
  const router = useRouter()
  const stytchClient = useStytchB2BClient()

  const [environmentOpen, setEnvironmentOpen] = useState<boolean>(false)

  const logout = async () => {
    const response = await stytchClient.session.revoke()

    if (response.status_code === 200) {
      router.push('/login')
    }
  }

  return (
    <SidebarWrapper visible={visible}>
      <LogoWrapper>
        <ListLink href="/" style={{ alignItems: 'center', display: 'flex' }}>
          <Logo color={LogoColor.Primary} height={24} />
        </ListLink>
      </LogoWrapper>
      <EnvironmentWrapper>
        <EnvironmentPicker
          onClick={() => setEnvironmentOpen(!environmentOpen)}
          open={environmentOpen}
        >
          <EnvironmentLabel>
            {ancho?.environment === AnchoEnvironment.Development && (
              <HiOutlineBeaker size={20} />
            )}
            {ancho?.environment === AnchoEnvironment.Production && (
              <HiStatusOnline size={20} />
            )}
            {ancho?.environment}
          </EnvironmentLabel>

          <HiOutlineChevronRight className="chevron" size={16} />
        </EnvironmentPicker>
        <EnvironmentPickerOptions open={environmentOpen}>
          <EnvironmentPickerOption
            onClick={() => {
              updateAncho({ environment: AnchoEnvironment.Development })
              setEnvironmentOpen(false)
            }}
            selected={ancho?.environment === AnchoEnvironment.Development}
          >
            <EnvironmentPickerOptionLabel>
              <HiOutlineBeaker size={20} /> Development
            </EnvironmentPickerOptionLabel>
            {ancho?.environment === AnchoEnvironment.Development && (
              <HiOutlineCheck color={anchoTheme.colors.white} size={20} />
            )}
          </EnvironmentPickerOption>
          <EnvironmentPickerOption
            onClick={() => {
              updateAncho({ environment: AnchoEnvironment.Production })
              setEnvironmentOpen(false)
            }}
            selected={ancho?.environment === AnchoEnvironment.Production}
          >
            <EnvironmentPickerOptionLabel>
              <HiStatusOnline size={20} />
              Production
            </EnvironmentPickerOptionLabel>
            {ancho?.environment === AnchoEnvironment.Development && (
              <HiOutlineCheck color={anchoTheme.colors.white} size={20} />
            )}
          </EnvironmentPickerOption>
        </EnvironmentPickerOptions>
      </EnvironmentWrapper>
      <LinksList>
        <ListLink href="/metrics" style={{ textDecoration: 'none' }}>
          <ListItem active={router.pathname === '/metrics'}>
            <HiOutlineChartSquareBar size={20} /> Metrics
          </ListItem>
        </ListLink>
        <ListLink href="/wallets" style={{ textDecoration: 'none' }}>
          <ListItem active={router.pathname === '/wallets'}>
            <HiOutlineCollection size={20} /> Wallets
          </ListItem>
        </ListLink>
        <ListLink href="/transactions" style={{ textDecoration: 'none' }}>
          <ListItem active={router.pathname === '/transactions'}>
            <HiOutlineMenuAlt1 size={20} /> Transactions
          </ListItem>
        </ListLink>
        <ListLink href="/settings" style={{ textDecoration: 'none' }}>
          <ListItem active={router.pathname === '/settings'}>
            <HiOutlineCog size={20} /> Settings
          </ListItem>
        </ListLink>
      </LinksList>
      {ancho && ancho.user && (
        <SidebarFooter>
          <UserEmail>{ancho.user.email_address}</UserEmail>
          <LogoutWrapper onClick={logout}>
            <HiOutlineLogout size={20} />
          </LogoutWrapper>
        </SidebarFooter>
      )}
    </SidebarWrapper>
  )
}

export default Sidebar
