import { Theme } from '@emotion/react'
import { useTheme } from '@table-library/react-table-library/theme'
import { getTheme } from '@table-library/react-table-library/baseline'
import { lighten, rgba } from 'polished'

export enum FlexAlign {
  Center = 'center',
  FlexEnd = 'flex-end',
  FlexStart = 'flex-start',
  Stretch = 'stretch',
}

export enum FlexDirection {
  Column = 'column',
  ColumnReverse = 'column-reverse',
  Row = 'row',
  RowReverse = 'row-reverse',
}

export enum FlexJustify {
  Center = 'center',
  FlexEnd = 'flex-end',
  FlexStart = 'flex-start',
  SpaceAround = 'space-around',
  SpaceBetween = 'space-between',
  SpaceEvenly = 'space-evenly',
}

export enum FlexWrap {
  NoWrap = 'nowrap',
  Wrap = 'wrap',
}

export enum LogoColor {
  Black = 'black',
  Dark = 'dark',
  Grey = 'grey',
  Light = 'light',
  Primary = 'primary',
  White = 'white',
}

export enum ThemeColor {
  Black = 'black',
  Light = 'light',
  Primary = 'primary',
  White = 'white',
}

export enum ThemeSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

const anchoTheme: Theme = {
  colors: {
    black: '#111212',
    dark: '#2B2E2E',
    error: '#E62929',
    grey: '#A4AAA6',
    info: '#29A3E6',
    light: '#F3F8F5',
    primary: '#FF3568',
    success: '#29E64C',
    warn: '#E6A429',
    white: '#ffffff',
  },
}

export const tableTheme = useTheme([
  getTheme(),
  {
    HeaderCell: `
      border-bottom: 1px solid ${anchoTheme.colors.light};
      color: ${anchoTheme.colors.dark};
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
    `,
    Row: `
      color: ${anchoTheme.colors.dark};

      &:hover {
        color: ${anchoTheme.colors.dark};
      }

      &.row-select-selected, &.row-select-single-selected {
        background-color: ${anchoTheme.colors.light};
        color: ${anchoTheme.colors.black};
        font-weight: 400;

        &:hover {
          color: ${anchoTheme.colors.black};
        }
      }
    `,
    Table: `
      --data-table-library_grid-template-columns: 44px minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr);

      background-color: ${anchoTheme.colors.white};
      border-radius: 15px;
      box-shadow: 0 2px 4px 0 ${rgba(anchoTheme.colors.black, 0.1)};
      margin-top: 15px;

      input[type='checkbox'] {
        accent-color: ${anchoTheme.colors.primary};
      }
    `,
  },
])

export default anchoTheme
