import Link from 'next/link'
import { lighten, rgba } from 'polished'
import styled from '@emotion/styled'

import anchoTheme from '@lib/theme'

export const EnvironmentLabel = styled.div({
  alignItems: 'center',
  display: 'flex',
  fontSize: '12px',
  fontWeight: 600,
  letterSpacing: '1px',
  textTransform: 'uppercase',

  svg: {
    marginRight: '10px',
  },
})

interface EnvironmentPickerProps {
  open?: boolean
}

export const EnvironmentPicker = styled.div<EnvironmentPickerProps>(
  {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 30px 15px 35px',

    '> svg.chevron': {
      transition: 'transform 0.3s ease-in',
    },
  },
  ({ open = false, theme = anchoTheme }) => ({
    backgroundColor: open ? theme.colors.black : 'transparent',
    color: open ? theme.colors.white : theme.colors.grey,

    '&:hover': {
      backgroundColor: open ? theme.colors.black : theme.colors.light,
      color: open ? theme.colors.white : theme.colors.dark,
    },

    '> svg.chevron': {
      transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
    },
  }),
)

interface EnvironmentPickerOptionProps {
  selected?: boolean
}

export const EnvironmentPickerOption = styled.li<EnvironmentPickerOptionProps>(
  {
    alignItems: 'center',
    borderBottomLeftRadius: '30px',
    borderTopLeftRadius: '30px',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '12px',
    fontWeight: 600,
    justifyItems: 'space-between',
    letterSpacing: '1px',
    listStyle: 'none',
    marginTop: '5px',
    padding: '15px 30px',
    textTransform: 'uppercase',
    width: '100%',

    '> svg': {
      marginLeft: 'auto',
    },
  },
  ({ selected = false, theme = anchoTheme }) => ({
    backgroundColor: selected ? theme.colors.primary : 'transparent',
    color: selected ? theme.colors.white : theme.colors.grey,
    cursor: selected ? 'default' : 'pointer',

    ':hover': {
      backgroundColor: selected ? theme.colors.primary : theme.colors.light,
      color: selected ? theme.colors.white : theme.colors.dark,
    },
  }),
)

export const EnvironmentPickerOptionLabel = styled.div({
  alignItems: 'center',
  display: 'flex',

  svg: {
    marginRight: '10px',
  },
})

interface EnvironmentPickerOptionsProps {
  open?: boolean
}

export const EnvironmentPickerOptions =
  styled.ul<EnvironmentPickerOptionsProps>(
    {
      display: 'flex',
      flexDirection: 'column',
      listStyle: 'none',
      margin: 0,
      overflow: 'hidden',
      padding: 0,
      paddingLeft: '30px',
      transition: 'height 0.2s ease-in',
      width: '100%',
    },
    ({ open = false }) => ({
      height: open ? '115px' : 0,
      paddingBottom: open ? '5px' : 0,
    }),
  )

export const EnvironmentWrapper = styled.div(
  {
    marginBottom: '15px',
    width: '100%',
  },
  ({ theme = anchoTheme }) => ({
    borderBottom: `1px solid ${theme.colors.light}`,
  }),
)

export const LinksList = styled.ul({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexWrap: 'wrap',
  listStyle: 'none',
  margin: 0,
  overflowY: 'scroll',
  padding: 0,
})

interface ListItemProps {
  active?: boolean
}

export const ListItem = styled.li<ListItemProps>(
  {
    alignItems: 'center',
    borderBottomLeftRadius: '30px',
    borderTopLeftRadius: '30px',
    display: 'flex',
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: '1px',
    listStyle: 'none',
    margin: '0 0 0 15px',
    padding: '15px 20px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: 'background-color 0.3s ease-in',
    width: '100%',

    '> svg': {
      marginRight: '15px',
    },
  },
  ({ active = false, theme = anchoTheme }) => ({
    backgroundColor: active ? anchoTheme.colors.primary : 'transparent',
    color: active ? theme.colors.white : theme.colors.grey,
    cursor: active ? 'default' : 'pointer',

    ':hover': {
      backgroundColor: active ? anchoTheme.colors.primary : theme.colors.light,
      color: active ? theme.colors.white : lighten(0.25, theme.colors.dark),
    },
  }),
)

export const ListLink = styled(Link)({
  display: 'flex',
  textDecoration: 'none',
  width: '100%',

  '&:not(:first-of-type)': {
    marginTop: '2.5px',
  },
  '&:not(:last-of-type)': {
    marginBottom: '2.5px',
  },
})

export const LogoutWrapper = styled.div({
  cursor: 'pointer',
  marginLeft: 'auto',
})

export const LogoWrapper = styled.div(
  {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '12px 15px',
  },
  ({ theme = anchoTheme }) => ({
    borderBottom: `1px solid ${theme.colors.light}`,
  }),
)

export const SidebarFooter = styled.div(
  {
    alignItems: 'center',
    borderTopRightRadius: '30px',
    display: 'flex',
    padding: '15px',
    width: '100%',
  },
  ({ theme = anchoTheme }) => ({
    backgroundColor: theme.colors.dark,
    color: theme.colors.white,
  }),
)

interface SidebarWrapperProps {
  visible?: boolean
}

export const SidebarWrapper = styled.aside<SidebarWrapperProps>(
  {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    position: 'fixed',
    top: 0,
    transition: 'left 0.3s ease-in',
    width: '300px',
    zIndex: 10,
  },
  ({ theme = anchoTheme, visible = false }) => ({
    backgroundColor: theme.colors.white,
    boxShadow: `0 4px 8px 0 ${rgba(theme.colors.black, 0.05)}`,
    color: theme.colors.grey,
    left: visible ? 0 : '-300px',
  }),
)

export const UserEmail = styled.div({
  cursor: 'pointer',
  fontSize: '13px',
  fontWeight: 600,
})
