import styled from '@emotion/styled'
import { mediaQueries } from '@lib/media-queries'
import anchoTheme from '@lib/theme'
import { rgba } from 'polished'

interface ContentWrapperProps {
  hasHeading?: boolean
  sidebarVisible?: boolean
}

export const ContentWrapper = styled.div<ContentWrapperProps>(
  {
    display: 'block',
    minHeight: 'calc(100vh - 30px)',
    padding: '30px',
    position: 'relative',
    width: '100%',

    '&:after': {
      clear: 'both',
      content: '""',
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  },
  ({ hasHeading = false, sidebarVisible = false }) => ({
    paddingTop: hasHeading ? '60px' : '30px',

    '&:after': {
      backdropFilter: 'blur(3px)',
      backgroundColor: rgba(anchoTheme.colors.black, 0.75),
      display: sidebarVisible ? 'block' : 'none',
    },
  }),
)

interface MenuButtonProps {}

export const MenuButton = styled.div<MenuButtonProps>({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  marginRight: '15px',
})

interface PageHeadingProps {
  isSmallScreen?: boolean
  sidebarVisible?: boolean
}

export const PageHeading = styled.div<PageHeadingProps>(
  {
    alignItems: 'center',
    display: 'flex',
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: '1px',
    padding: '15px 30px',
    position: 'fixed',
    textTransform: 'uppercase',
    transition: 'left 0.3s ease-in',
    top: 0,
    width: '100%',
    zIndex: 100,
  },
  ({ isSmallScreen = false, sidebarVisible = false, theme = anchoTheme }) => ({
    backgroundColor: theme.colors.black,
    color: theme.colors.white,
    left: sidebarVisible || !isSmallScreen ? '300px' : 0,
  }),
)

interface PageSectionHeadingProps {
  first?: boolean
}

export const PageSectionHeading = styled.h2<PageSectionHeadingProps>(
  {
    fontSize: '1.125rem',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  ({ first = false, theme = anchoTheme }) => ({
    color: theme.colors.dark,
    marginTop: first ? 0 : '30px',
  }),
)

interface PageWrapperProps {
  isSmallScreen?: boolean
  sidebarVisible?: boolean
}

export const PageWrapper = styled.div<PageWrapperProps>(
  {
    position: 'relative',
    transition: 'padding 0.3s ease-in',
  },
  ({ sidebarVisible = false }) => ({
    [`${mediaQueries.largeDesktop}`]: {
      paddingLeft: !sidebarVisible ? 0 : '300px',
    },
  }),
)
