import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { ThemeProvider } from '@emotion/react'
import {
  useStytchB2BClient,
  useStytchMember,
  useStytchMemberSession,
} from '@stytch/nextjs/b2b'

import type { IAnchoContext } from '@types'
import anchoTheme from '@lib/theme'
import { AnchoContextProvider } from '@context/ancho'
import MenuIcon from '@components/core/menu-icon'
import Sidebar from './sidebar'
import { ContentWrapper, MenuButton, PageHeading, PageWrapper } from './style'
import { HiOutlineArrowLeft, HiOutlineMenu } from 'react-icons/hi'
import { breakpoints } from '@lib/media-queries'
import { AnchoEnvironment } from '@lib/ancho'

interface PageProps extends PropsWithChildren {
  noAuthCheck?: boolean
  noSidebar?: boolean
  title?: string
}

const Page: FC<PageProps> = ({
  children,
  noAuthCheck = false,
  noSidebar = false,
  title,
}) => {
  const router = useRouter()
  const { fromCache, session } = useStytchMemberSession()
  const { member } = useStytchMember()
  const stytchClient = useStytchB2BClient()

  const [anchoContext, setAnchoContext] = useState<IAnchoContext>({
    environment: AnchoEnvironment.Development,
  })
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false)
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(null)

  const handleMenuClick = () => {
    setSidebarVisible(!sidebarVisible)
  }

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < breakpoints[3])
  }

  const loadStytchData = async () => {
    if (typeof window !== 'undefined') {
      const newAncho: Partial<IAnchoContext> = {}

      const environment = localStorage.getItem('ancho:environment')
      if (environment) {
        newAncho.environment = environment as AnchoEnvironment
      } else {
        newAncho.environment = AnchoEnvironment.Development
        localStorage.setItem('ancho:environment', AnchoEnvironment.Development)
      }

      if (member) {
        const organization = await stytchClient.organization.get()

        if (organization !== anchoContext.organization) {
          newAncho.organization = organization
        }

        if (member !== anchoContext.user) {
          newAncho.user = member
        }
      }

      updateAnchoContext(newAncho)
    }
  }

  const updateAnchoContext = (newAncho: Partial<IAnchoContext>) => {
    console.log(`Updating anchoContext with: `, newAncho)
    setAnchoContext({ ...anchoContext, ...newAncho })
  }

  useEffect(() => {
    if (session && !fromCache) {
      // There's a valid session, so we can load the user data
      loadStytchData()
    }
  }, [fromCache, session])

  useEffect(() => {
    if (!isSmallScreen && sidebarVisible) {
      setSidebarVisible(false)
    }
  }, [isSmallScreen, sidebarVisible])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      !noAuthCheck &&
      !document.cookie.includes('ancho_session')
    ) {
      router.push('/login')
    }
  }, [])

  return (
    <ThemeProvider theme={anchoTheme}>
      <AnchoContextProvider value={anchoContext}>
        <PageWrapper
          isSmallScreen={isSmallScreen}
          sidebarVisible={
            (sidebarVisible && !noSidebar) || (!isSmallScreen && !noSidebar)
          }
        >
          <Sidebar
            updateAncho={updateAnchoContext}
            visible={
              (sidebarVisible && !noSidebar) || (!isSmallScreen && !noSidebar)
            }
          />

          {!noSidebar && !!title && (
            <PageHeading
              isSmallScreen={isSmallScreen}
              sidebarVisible={
                (sidebarVisible && !noSidebar) || (!isSmallScreen && !noSidebar)
              }
            >
              {isSmallScreen && (
                <MenuButton onClick={handleMenuClick}>
                  {sidebarVisible ? (
                    <HiOutlineArrowLeft size={20} />
                  ) : (
                    <HiOutlineMenu size={20} />
                  )}
                </MenuButton>
              )}
              {title}
            </PageHeading>
          )}

          <ContentWrapper
            hasHeading={!noSidebar && !!title}
            sidebarVisible={sidebarVisible && !noSidebar && isSmallScreen}
          >
            {children}
          </ContentWrapper>
        </PageWrapper>
      </AnchoContextProvider>
    </ThemeProvider>
  )
}

export default Page
