import { createContext, useContext } from 'react'
import { IAnchoContext } from '@types'

export const AnchoContext = createContext<IAnchoContext>(null)

export const AnchoContextProvider = AnchoContext.Provider

export const useAncho = () => {
  const ancho = useContext(AnchoContext)

  return ancho
}
